/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

export type PopupMessage = {
  message: string;
  type: MessageType;
  traceId?: string;
  seen: boolean; // true = won't show in ErrorPopup, but remain visible in LocalAlertMessage's details
};

export enum MessageType {
  INTERNAL_GET_ERROR,
  RELEASE_NOT_VALID,
  DEFAULT_ERROR_TYPE,
}

// Add the translation in en.json under errors
export enum ErrorMessage {
  CM_DEPLOYMENTS_CU_GET = 'CM_DEPLOYMENTS_CU_GET',
  CM_DEPLOYMENTS_ORU20_GET = 'CM_DEPLOYMENTS_ORU20_GET',
  CM_DEPLOYMENTS_BIGLOOP_GET = 'CM_DEPLOYMENTS_BIGLOOP_GET',
  CM_DEPLOYMENTS_E311ORU_GET = 'CM_DEPLOYMENTS_E311ORU_GET',
  CM_CAMPAIGNS_DLM_GET = 'CM_CAMPAIGNS_DLM_GET',
  CM_RELEASES_ORU20_GET = 'CM_RELEASES_ORU20_GET',
  CM_RELEASES_BIGLOOP_GET = 'CM_RELEASES_BIGLOOP_GET',
  CM_SW_PROJECT_DRM_GET = 'CM_SW_PROJECT_DRM_GET',
  CM_SW_PROJECT_AEC_GET = 'CM_SW_PROJECT_AEC_GET',
  CM_RELEASES_ORU20_NOT_VALID_DETAILS = 'CM_RELEASES_ORU20_NOT_VALID_DETAILS',
  BACKEND_SERVICE_DOWN = 'BACKEND_SERVICE_DOWN',
  BACKEND_SERVICE_DOWN_ORU20 = 'BACKEND_SERVICE_DOWN_ORU20',
  BACKEND_SERVICE_DOWN_BIGLOOP = 'BACKEND_SERVICE_DOWN_BIGLOOP',
  CM_VEHICLE_LIST_SYNCH_FAILED = 'CM_VEHICLE_LIST_SYNCH_FAILED',
  CM_ORU_SMART_CAMPAIGNS_GET = 'CM_ORU_SMART_CAMPAIGNS_GET',
}
