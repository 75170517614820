/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { errorPopupWrapper } from '@/components/Error/errorPopupWrapper';
import { ErrorMessage } from '@/models/error/ErrorMessagesModels';
import { RoleEnum } from '@/models/user/RoleEnum';
import { hasRole } from '@/models/user/User';
import { LazyState } from '@/utils/lazyState';
import { CommonOrigin } from '@/modules/campaign_management/models/CommonModels';
import {
  buildCommonState,
  transformCampaignToCommonCampaign,
} from '@/modules/campaign_management/utils/transformToCommon';
import * as DataLoadService from '@/modules/data_load_management/services/DataLoadService';

export const dataLoadCampaigns = new LazyState(
  errorPopupWrapper(DataLoadService.getCampaigns, [], ErrorMessage.CM_CAMPAIGNS_DLM_GET),
).getReactiveWrapper();

export const {
  allCommonState: allDlmCampaignsState,
  allCommonStateInstance: allDlmCampaigns,
  hasCommonRoles: hasDlmCampaignRole,
} = buildCommonState(transformCampaignToCommonCampaign, [
  [CommonOrigin.DATALOAD, [RoleEnum.PERM_DATALOAD_WRITE], dataLoadCampaigns],
]);

export const hasCommonCampaignRoles = (): boolean => {
  // Check if the user has read or write permissions
  return (
    hasRole(RoleEnum.PERM_DATALOAD_READ) ||
    hasRole(RoleEnum.PERM_DATALOAD_WRITE) ||
    hasRole(RoleEnum.PERM_SMART_CAMPAIGN_READ) ||
    hasRole(RoleEnum.PERM_SMART_CAMPAIGN_WRITE)
  );
};
