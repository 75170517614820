/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';

export enum CommonOrigin {
  CU = 'CU',
  ORU_NEXT = 'ORUnext Release',
  BIGLOOP = 'BIGLOOP',
  MQ_BEVO_ORU = 'MQBevo ORU',
  DATALOAD = 'DATALOAD',
  DRM = 'DRM',
  AEC = 'AEC',
  ORU_SMART_CAMPAIGN = 'ORU_SMART_CAMPAIGN',
}

export const ZodBrandCodeEnum = z.enum(['V', 'A', 'S', 'C', 'N', 'P']); // Keep this in sync with i18n: common.brandCode.

export const UserInfo = z.object({
  id: z.string(), // aka Subject
  username: z.string(), // aka Concern-Id aka Group-Id
  email: z.string(),
  fullName: z.string(),
});
export type UserInfo = z.infer<typeof UserInfo>;

export const CommonRelease = z.object({
  id: z.string(),
  version: z.string(),
  vehiclePlatform: z.string().optional(),
});
export type CommonRelease = z.infer<typeof CommonRelease>;

export const JFrogUriType = z.enum(['FILE', 'DIRECTORY']);

export const JFrogUri = z.lazy(() =>
  z.object({
    uri: z.string(),
    jFrogType: JFrogUriType,
  }),
);
export type JFrogUri = z.infer<typeof JFrogUri>;

export const ReleaseNotesHealthStatusEnum = z.enum(['OK', 'NOT_FOUND', 'NOT_AVAILABLE']);
export const ReleaseNotesHealthStatus = ReleaseNotesHealthStatusEnum.enum;

export const CommonSwProjectStatusEnum = z.enum(['ACTIVE', 'INACTIVE']);

// Please keep in mind, that this was the old CommonRelease and
// for the technologies ORU 2.0, there is no SwProject, so that this is effectively a CommonRelease for these technologies for now.
export const CommonSwProject = z.object({
  id: z.string(),
  moduleVersion: z.string().optional(),
  name: z.string(),
  status: CommonSwProjectStatusEnum,
  releaseCount: z.number().nullable(),

  // "null" is needed for developer view (mqBevo / bigLoop campaigns)
  latestVersion: z.string().nullish() || null,

  // nullish() because of downward-compatibility. once all endpoints provide this data, it should be defined as mandatory
  // "null" is needed for developer view (mqBevo / bigLoop releases campaigns)
  owner: UserInfo.nullish() || null,

  // DEPRECATED! Will be removed soon. Defaults to TRUE
  valid: z.boolean(),
});
export type CommonSwProject = z.infer<typeof CommonSwProject>;

export const CommonDeploymentStatusEnum = z.enum([
  'CREATED',
  'VEHICLE_LIST_ADDED',
  'REQUESTED',
  'ROLLOUT_INITIATED',
  'ROLLOUT_SUCCESSFUL',
  'ROLLOUT_FAILED',
  'ENABLEMENT_SUCCESSFUL',
]);
export type CommonDeploymentStatusEnum = z.infer<typeof CommonDeploymentStatusEnum>;

export const CommonDeveloperModeDeploymentStatusEnum = z.enum([
  'CREATED',
  'VEHICLE_LIST_ADDED',
  'IN_PREPARATION',
  'READY_TO_ROLLOUT',
  'FINISHED',
  'ACTIVATED',
  'ROLLED_OUT',
  'REQUESTED',
  'ROLLOUT_INITIATED',
  'ROLLOUT_SUCCESSFUL',
  'ROLLOUT_FAILED',
  'ENABLEMENT_SUCCESSFUL',
]);

export type CommonDeveloperModeDeploymentStatusEnum = z.infer<typeof CommonDeveloperModeDeploymentStatusEnum>;

export const CommonDeploymentListEntry = z.object({
  id: z.string(),
  name: z.string(),
  status: CommonDeploymentStatusEnum,
});
export type CommonDeploymentListEntry = z.infer<typeof CommonDeploymentListEntry>;

// Dm: DeveloperMode
export const CommonDmDeploymentListEntry = z.object({
  id: z.string(),
  moduleVersion: z.string().optional(),
  status: CommonDeveloperModeDeploymentStatusEnum,
  measureId: z.string().optional(),
  author: UserInfo.optional(),
  lastEditedBy: UserInfo.optional(),
  lastModifiedDateTime: z.string().optional(),
  name: z.string(),
  releaseId: z.string().optional(),
});
export type CommonDmDeploymentListEntry = z.infer<typeof CommonDmDeploymentListEntry>;

export const CommonDeployment = CommonDeploymentListEntry;
export type CommonDeployment = z.infer<typeof CommonDeployment>;

export const CommonDeveloperModeDeploymentListEntryWithOrigin = CommonDmDeploymentListEntry.extend({
  origin: z.nativeEnum(CommonOrigin),
});
export type CommonDeveloperModeDeploymentListEntryWithOrigin = z.infer<
  typeof CommonDeveloperModeDeploymentListEntryWithOrigin
>;

export type CommonSwProjectWithOrigin = CommonSwProject & { origin: CommonOrigin };

export const HealthStatusEnum = z.enum(['DOWN', 'UP']);
export const HealthStatus = HealthStatusEnum.enum;
export type HealthStatus = z.infer<typeof HealthStatusEnum>;

export const HealthResponse = z.object({
  status: HealthStatusEnum,
});
export type HealthResponse = z.infer<typeof HealthResponse>;

export const StatusIconEnum = z.enum(['INCOMPLETE', 'COMPLETE']);
export const StatusIcon = StatusIconEnum.enum;
export type StatusIcon = z.infer<typeof StatusIconEnum>;

export const LogStatusTestDeploymentEnum = z.enum(['Successful', 'Failed']);
export type LogStatusTestDeploymentEnum = z.infer<typeof LogStatusTestDeploymentEnum>;

export const TestDeploymentListEntry = z.object({
  id: z.string(),
  name: z.string(),
  status: CommonDeploymentStatusEnum,
  logResultStatus: LogStatusTestDeploymentEnum,
});
export type TestDeploymentListEntry = z.infer<typeof TestDeploymentListEntry>;
